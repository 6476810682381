import React, { FC, useEffect, useState } from 'react';
import { Button, Table, Modal, notification } from 'antd';
import { useGetOnhandIventory } from '../utils/hooks';
import { AuthTokenType, OnhandInventoryProps } from '../utils/types';
import { getAuthToken, getOnhandInventory, getproductinventory } from '../utils/functions';
import AddOnHandInventoryForm from '../components/AddOnHandInventoryForm';
import ContentLayout from '../components/ContentLayout';
import axios from 'axios';
import { onhandUrl } from '../utils/network';

const OnHandInventory: FC = () => {

  const [productinvVisible, setProductinvVisible] = useState<boolean>(false);
  const [productinv, setproductinv] = useState<any[]>([]);
  const [prodinvFetching, setprodinvFetching] = useState<boolean>(false);

  const handlenameClick = (productId: number,locationId:number) => {
   
    setProductinvVisible(true);
    getproductinventory(setproductinv,setprodinvFetching, productId,locationId);
  };



  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
       {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      render: (name: number, record: OnhandInventoryProps) => (
        <a onClick={() => handlenameClick(record.productid, record.locationid)}>{name}</a>
      ),

    },

    {
      title: 'Category',
      dataIndex: 'cat',
      key: 'cat',
    },
    {
      title: 'Sub Category',
      dataIndex: 'subcat',
      key: 'subcat',
    },
    {
      title: 'Storage Location',
      dataIndex: 'storage',
      key: 'storage',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Units on hand',
      dataIndex: 'totalunits',
      key: 'totalunits',
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: OnhandInventoryProps) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },


  ];

  const [onhandinventory, setOnHandInventory] = useState<OnhandInventoryProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingInventory, seteditingInventory] = useState<OnhandInventoryProps | null>(null);

  useGetOnhandIventory(setOnHandInventory, setFetching);

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onSuccessAddOnHandInventory = () => {
    setDrawerVisible(false);
    getOnhandInventory(setOnHandInventory, setFetching); // Refresh on-hand inventory list
  };



  useEffect(() => {
    // When editingCategory changes, open the modal
    if (editingInventory) {
      setDrawerVisible(true);
    }
  }, [editingInventory]);

  const onCloseWithoutEditing = () => {
    seteditingInventory(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: OnhandInventoryProps) => {
    seteditingInventory(record);
  };
  const handleDelete = (inventoryId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this category?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a category
          await axios.delete(`${onhandUrl}/${inventoryId}`, headers);
  
          // After successful deletion, fetch updated categories
          getOnhandInventory(setOnHandInventory, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };



  return (
    <ContentLayout
      pageTitle="Inventory"
      buttontitle="Add On Hand Inventory"
      setModalState={setDrawerVisible}
      dataSource={onhandinventory as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true} 

    >
            <Modal
        visible={productinvVisible}
        onCancel={() => {
          setProductinvVisible(false)
          setproductinv([])
        }}
        footer={null}
      >
        {prodinvFetching ? (
          <p>Loading...</p>
        ) : (
          <Table
            className="custom-table"
            dataSource={productinv}
            columns={[
              {
                title: "Date",
                dataIndex: "date",
                key: "date",
                render: (dateString: string) => {
                  const date = new Date(dateString);
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const day = String(date.getDate()).padStart(2, '0');
                  return `${year}-${month}-${day}`;
                },
              },
              {
                title: "Inventory",
                dataIndex: "inventory_level",
                key: "inventory_level",
              },
              {
                title: "Previous Value",
                dataIndex: "previous_value",
                key: "previous_value",
              },
              {
                title: "Difference",
                dataIndex: "difference",
                key: "difference",
              },
              {
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
              },

            ]}
            rowKey="id"
          />
        )}
      </Modal>

      <AddOnHandInventoryForm
        isVisible={drawerVisible}
        onClose={onCloseDrawer}
        onSuccessCallBack={onSuccessAddOnHandInventory}
        editingInventory={editingInventory}
        onCloseWithoutEditing={onCloseWithoutEditing}

      />
    </ContentLayout>
  );
};

export default OnHandInventory;