import  {AxiosError} from 'axios'

export interface DataProps{
    [key: string]:string|boolean|number|null|React.ReactElement|((text: string) => React.ReactNode)| undefined;
    
 
}

export interface CustomAxiosError extends Omit<AxiosError,'response'>{
    response? :{
        data:{
            error:string
        } 
    }
}

export interface AuthTokenType{
    headers:{
        Authorization:string}
}

export interface UserType{
    email:string
    fullname:string
    id:number
    created_at:string
    role:string
    last_login:string
    
}

export interface AuthProps{
    errorCallBack?:()=>void,
    successCallBack?:()=>void,
}


export interface StoreProps{
    user:UserType | null,
    updatePasswordUserId:number|null
}

export enum ActionTypes{
    UPDATE_USER_INFO ="[action] update user info",
    UPDATE_PASSWORD_USER_ID ="[action] update password id"
}

export type ActionProps={
    type: ActionTypes.UPDATE_USER_INFO,
    payload:UserType | null
}| {
    type: ActionTypes.UPDATE_PASSWORD_USER_ID,
    payload:number | null
}

export interface StoreProviderProps{
    state:StoreProps,
    dispatch:(arg:ActionProps)=>void
}




export interface UserProps{
    created_at:string
    email:string
    fullname:string
    is_active:string
    last_login:string
    role:string
    key?:number
    id:number
  
  }
  

 
  export interface AddGroupFormModalProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
}



export interface AddOnHandInventoryFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingInventory?: OnhandInventoryProps | null;
  onCloseWithoutEditing:()=>void

}


export interface OnhandInventoryProps{
  key:string;
  id:number
  locationid:number
  productid:number

  totalunits:number
  status:string
  sku_code:{
    id:number
    code:string
    name:string
    
    category:{
      name:string
      parent:{
        name:string
      }
    }
    }
  storage_location:{
      name:string
      id:number
      }
      created_at:string
  }


export interface GroupProps{
    name:string
    
  }


export interface AddUserFormModalProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingUser?: UserProps | null;
    onCloseWithoutEditing:()=>void

}
 

export interface category {
  id: number;
  name: string;
  parent: category | null; // Allow parent to reference another category or be null for top-level categories
  is_parent: boolean;
}

export interface AddCategoryFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingCategory?: category | null;
    onCloseWithoutEditing:()=>void

}

export interface AddUnitofmeasureFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingUnitofmeasure?: unitofmeasure | null;
    onCloseWithoutEditing:()=>void

}


  // Unit of Measure model
export interface unitofmeasure {
    id: number;
    name: string;
    is_active: boolean;
    
  }



  
export interface AddLocationFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingLocation?: location | null;
    onCloseWithoutEditing:()=>void
}


  // Location model
export interface location {
    id: number;
    name: string;
    type: string;
    address: string;
    city: string;
    state: string;
    country: string;
  }


  
export interface AddProductFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingProduct?: product | null;
    onCloseWithoutEditing:()=>void
}


  // Product model
export interface product {
    id: number;
    is_active:boolean;
    quantity?:number;
    name: string;
    category: category;
    sku_uom:unitofmeasure;
    uom:string,
    curr:string
    cat:string
    subcat:string
    costperwholeunit:number 
    subunitsaleprice :number
    subunitsperwholeunit:number 
    revenueperwholeunit:number
    marginperwholeunit:number
    marginperwholeunitpercent:number
    last_count_date:Date|moment.Moment|string|any;
    inventory?:number
  }
  
        

                          export interface InventoryProps{
                            id: number;
                            name: string;
                            code: string;
                            sku_description: string;
                            category: category;
                            total_units:number;
                          }
                          


 
export interface AddSupplierFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingSupplier?: supplier | null;
  onCloseWithoutEditing:()=>void
}

// Supplier model
export interface supplier {
  id: number;
  name: string;
  email: string;
  address: string;
  city: string;
  sales_person_name:string,
  sales_person_email:string
  country: string;
  phone: string;
  type: string;
}


export interface AddRemovePurchaseItemProps{
  [key:number]:number
}

export interface EditPurchaseOrderProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingPurchase?: purchaseProps | null;
  onCloseWithoutEditing:()=>void
}

export interface purchaseProps
{
        key:string;
        id:number
        item:string
        total_price?:string
        po_number?:string
        departure_date?:Date|moment.Moment|string 
        shipping_company? :string
        expected_delivery_date?:Date|moment.Moment|string
        actual_delivery_date?:Date|moment.Moment|string
        quantity:number
        status?:string
        amount?:number|string
        cost:number
        change_delivery_date?:Date
        action?:React.ReactElement

        from_location?:{
          name:string
          email:string
          }
          to_location?:{
            name:string
            }
        purchaseorder_items?:{
          quantity:number
          item:{
                id:string
                name:string
                quantity:number
                cost:number
              }
                }[],
        
        }