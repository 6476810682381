//export const BaseUrl="http://127.0.0.1:8000/"

//export const BaseUrl="http://52.200.175.226/api/"
export const BaseUrl="https://unifiedinventory.com/api/"

export const LoginUrl = BaseUrl+ "user/login"
export const MeUrl = BaseUrl + "user/Me"
export const CreateUserUrl = BaseUrl + "user/create-user"
export const UsersUrl = BaseUrl + "user/users"
export const UpdatePasswordUrl = BaseUrl + "user/update-password"
export const ForgotPasswordUrl = BaseUrl + "user/ForgotPasswordView"
export const ResetPasswordUrl = BaseUrl + "user/ResetPasswordView"



export const categoriesUrl = BaseUrl + "masterdata/categories"
export const unitofmeasuresUrl = BaseUrl + "masterdata/unitofmeasures"
export const currenciesUrl = BaseUrl + "masterdata/currencies"
export const locationsUrl = BaseUrl + "masterdata/locations"
export const productsUrl = BaseUrl + "masterdata/products"
export const productswithinventoryUrl = BaseUrl + "masterdata/productswithinventory"



export const onhandUrl = BaseUrl + "transactiondata/onhandinventory"
export const InventoryUrl = BaseUrl + "transactiondata/InventoryViewSet"



export const SummaryMasterUrl = BaseUrl + "transactiondata/summarymasterview"
export const SummaryTransactionUrl = BaseUrl + "transactiondata/summarytransactionview"



export const InventorySummaryUrl = BaseUrl + "transactiondata/onhandsummaryview"

export const masterdatauploadURL = BaseUrl + "masterdata/UploadCSV/"

export const InventoryMD04Url = BaseUrl + "transactiondata/InventoryViewSetMD04"
export const SankeyChartURL = BaseUrl + "transactiondata/SankeyChartView"
export const productpriceViewSetURL = BaseUrl + "masterdata/productpriceViewSet"
export const productinventoryViewSetURL = BaseUrl + "masterdata/productinventoryViewSet"

export const suppliersUrl = BaseUrl + "masterdata/suppliers"
export const purchaseorderUrl = BaseUrl + "transactiondata/purchaseorder"
export const sendpoUrl = BaseUrl + "transactiondata/purchaseorder/send_email_purchase_order"
export const viewpoUrl = BaseUrl + "transactiondata/purchaseorder/view_purchase_order"
